.form-label {display: inherit;text-align: left; font-weight: 300;}
.form-row {margin-bottom: 15px;}
.form-row > .col, .form-row > [class*="col-"]{padding-right: 15px; padding-left: 15px;}
.btn-signup {
    color: #fff;
    background: var(--gradient-color);
    width: 100%;
    padding: 0px 10px;
    height: 45px;
    border-radius: 7px;
    font-size: 15px;
    font-weight: 600;
    color: var(--black-color);
    border: 0;
}
.auth-row{margin: 30px 0;}
.form-control.has-error {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}
.btn-signup-loading {width: 100%;padding: 9px 12px ;}
.form-control{height: 45px;border-radius: 7px;}
.custom-file{margin-bottom: 10px;height: 50px;line-height: 50px;}
.custom-file .custom-file-input, .custom-file .custom-file-label{height: 50px;line-height: 50px; padding: 0 15px;width: 100%;max-width: 100% !important;}
.custom-file .custom-file-label::after{height: 50px; line-height: 50px; padding: 0 15px;}
.content-panel .box-panel .form-label{width: 100%;}
/* .content-panel .box-panel label{max-width: 150px; width: 100%;} */

.ant-radio-group .ant-radio-wrapper{width: auto !important;}