@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

@font-face {
  font-family: "montserratlight";
  src: url("./fonts/monteserrat/montserrat-light-webfont.woff2") format("woff2"),
    url("./fonts/monteserrat/montserrat-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratregular";
  src: url("./fonts/monteserrat/montserrat-regular-webfont.woff2")
      format("woff2"),
    url("./fonts/monteserrat/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "montserratmedium";
  src: url("./fonts/monteserrat/montserrat-medium-webfont.woff2")
      format("woff2"),
    url("./fonts/monteserrat/montserrat-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "montserratbold";
  src: url("./fonts/monteserrat/montserrat-bold-webfont.woff2") format("woff2"),
    url("./fonts/monteserrat/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap");

:root {
  --main-font-family: 'Roboto', sans-serif;
  --heading-font-family: 'Barlow', sans-serif;
  --main-color: #1657CB;
  --optional-color: #00B9FF;
  --white-color: #ffffff;
  --black-color: #141414;
  --paragraph-color: #646464;
  --gradient-color: linear-gradient(to top, #00b9ff, #00c7ff, #00d4fc, #00e0f5, #0cebeb);
  --font-size: 16px;
  --transition: .6s;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: var(--black-color);
  font-family: var(--heading-font-family);
  font-weight: 800;
}

/* New Css */
.match_job {
	margin-top: 90px;
}
.match_job .box-panel {
	box-shadow: none;
	margin-top: 20px;
}
.match_job .btn-light{background-color: #fff;}
.match_job .btn-light:hover{color: #b95c21; border-color: #b95c21;background-color: #fff;}
.match_job .multi-select .dropdown-container{border: 0;}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.header-banner-outer {
  margin: 0 auto;
}
nav.navbar {
  background-color: #0878c0;
  padding: 5px 0;
  margin-bottom: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 8px rgba(0, 0, 0, 0.14);
}
@media (min-width: 1300px) {
  nav.navbar {
    padding: 5px 0;
  }
}
nav.navbar .navbar-brand {
  display: inline-block;
  margin-bottom: 0;
  width: 100%;
  padding: 0;
}
@media (max-width: 767px) {
  nav.navbar .navbar-brand {
    padding: 15px;
  }
  .mb767-15 {
    margin-bottom: 15px;
  }
}
nav.navbar .navbar-brand img {
  max-width: 100%;
}
@media (min-width: 1300px) {
  nav.navbar .navbar-brand {
    max-width: 500px;
    height: auto;
    padding: 0 15px;
  }
}
/* line 251, ../scss/style.scss */
nav.navbar .navbar-toggle {
  background: white;
  border: none;
  margin-top: 15px;
  padding: 10px;
}
@media (max-width: 991px) {
  nav.navbar .navbar-toggle {
    margin-top: 10px;
  }
}
@media (max-width: 400px) {
  nav.navbar .navbar-toggle {
    margin-right: 0px;
  }
}
nav.navbar .navbar-toggle .icon-bar {
  width: 32px;
  height: 2px;
  background: #292929;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -moz-transform: translate(0, 10px) rotate(45deg);
  -ms-transform: translate(0, 10px) rotate(45deg);
  -webkit-transform: translate(0, 10px) rotate(45deg);
  transform: translate(0, 10px) rotate(45deg);
}
nav.navbar .navbar-toggle .icon-bar + .icon-bar {
  -moz-transform: translate(0, 0) rotate(-45deg);
  -ms-transform: translate(0, 0) rotate(-45deg);
  -webkit-transform: translate(0, 0) rotate(-45deg);
  transform: translate(0, 0) rotate(-45deg);
  margin-top: 9px;
}
nav.navbar .navbar-toggle .icon-bar + .icon-bar + .icon-bar {
  opacity: 0;
  filter: alpha(opacity=0);
  margin-top: 5px;
  margin-bottom: 5px;
}
nav.navbar .navbar-toggle:hover,
nav.navbar .navbar-toggle:focus,
nav.navbar .navbar-toggle:active {
  background-color: white !important;
}
nav.navbar .navbar-toggle:hover .icon-bar,
nav.navbar .navbar-toggle:focus .icon-bar,
nav.navbar .navbar-toggle:active .icon-bar {
  background: #292929;
}
nav.navbar .navbar-toggle.collapsed {
  background: white;
}
nav.navbar .navbar-toggle.collapsed .icon-bar {
  -moz-transform: none;
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
  background: #292929;
  margin-top: 5px;
}
nav.navbar .navbar-toggle.collapsed .icon-bar + .icon-bar + .icon-bar {
  display: block;
  opacity: 1;
  filter: alpha(opacity=100);
}
nav.navbar .navbar-toggle.collapsed:hover,
nav.navbar .navbar-toggle.collapsed:focus,
nav.navbar .navbar-toggle.collapsed:active {
  background: white;
}
nav.navbar .navbar-toggle.collapsed:hover .icon-bar,
nav.navbar .navbar-toggle.collapsed:focus .icon-bar,
nav.navbar .navbar-toggle.collapsed:active .icon-bar {
  background: #292929;
}
nav.navbar .navbar-toggle:hover,
nav.navbar .navbar-toggle:focus {
  background: white;
}
nav.navbar .navbar-toggle:hover .icon-bar,
nav.navbar .navbar-toggle:focus .icon-bar {
  background: #292929;
}
@media (min-width: 768px) {
  nav.navbar .navbar-collapse.collapse {
    float: right;
  }
}
nav.navbar .navbar-nav {
  align-items: center;
  justify-content: right;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
}
@media (max-width: 991px) {
  nav.navbar .navbar-nav {
    flex-direction: column;
  }
}
nav.navbar .navbar-nav li {
  list-style: none;
  font-size: 20px;
}
nav.navbar .navbar-nav li a,
nav.navbar .navbar-nav li span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  padding-right: 30px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  transition: 0.5s;
}
nav.navbar .navbar-nav li.sub-menu {
  position: relative;
}
nav.navbar .navbar-nav li.sub-menu li {
  padding: 0;
}
nav.navbar .navbar-nav li.sub-menu ul.dropdown {
  min-width: 18rem;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
  position: absolute;
  z-index: 99999999;
  right: 0;
  top: 43px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 12px;
}
nav.navbar .navbar-nav li.sub-menu ul.dropdown.profile-menu {
  top: 54px;
}
nav.navbar .navbar-nav li.sub-menu ul.dropdown a {
  display: inline-block !important;
  color: #000;
  padding: 5px 0;
  font-size: 14px;
}
nav.navbar .navbar-nav li.sub-menu ul.dropdown span {
  display: inline-block !important;
  color: #000;
  padding: 5px 0;
  font-size: 14px;
  cursor: pointer;
}
nav.navbar .navbar-nav li.sub-menu:hover ul.dropdown {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 1300px) {
  nav.navbar .navbar-nav li a {
    font-size: 16px;
  }
}
@media (max-width: 1200px) {
  nav.navbar .navbar-nav li a {
    font-size: 16px;
  }
}
@media (max-width: 1100px) and (min-width: 992px) {
  nav.navbar .navbar-nav li a {
    padding-right: 12px;
  }
}
nav.navbar .navbar-nav li a:hover {
  text-decoration: none;
  color: #fff;
}
nav.navbar .navbar-nav li a img {
  margin-right: 10px;
}
nav.navbar .navbar-nav li.sk-login-dropdown a {
  height: 40px;
  font-size: 16px;
  width: 120px;
  border-radius: 30px;
  text-align: center;
  justify-content: center;
  border: 1px solid #0878c0;
  margin-right: 30px;
  padding: 0;
}
@media (min-width: 1300px) {
  nav.navbar .navbar-nav li.sk-login-dropdown a {
    border: 2px solid #0878c0;
    height: 50px;
    font-size: 20px;
    margin-right: 50px;
    margin-left: 20px;
    width: 150px;
  }
}
nav.navbar .navbar-nav li.sk-login-dropdown a:hover {
  background-color: #0878c0;
  color: #fff;
}
nav.navbar .navbar-nav li.sk-login-dropdown a:after {
  display: none;
}
nav.navbar .navbar-nav li.sk-w .sk-air-info {
  margin-left: 0;
  display: flex;
  color: #fff;
}
.navbar-header {
  width: 30%;
}
.navbar-title {
  color: #fff;
  font-size: 40px;
  line-height: 1.1;
  max-width: 500px;
  width: 100%;
}
@media (max-width: 767px) {
  .navbar-header {
    width: 60%;
  }
  .navbar-title {
    font-size: 30px;
  }
}
.body-bg {
  /* background-color: #f3f4f5; */
  background-image: url("../assets/images/bg-pattern.png");
  background-position: center center;
  background-repeat: repeat;
  padding-bottom: 0px;
  min-height: 960px;
}
/* .content {
  padding: 50px 0;
} */
.box-panel {
   -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 100px;
  padding: 25px;
  background-color: #fff;
  box-shadow: 0 7px 30px rgba(0, 0, 0, 0.07);
}

.mr-10 {
  margin-right: 10px;
}
.btn-secondary {
  color: #222222;
  background-color: #fff;
  border-color: #222222;
}
.btn {
  border-radius: 8px;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;
}
.btn-primary {
  color: #fff;
  background-color: #0878c0;
  border-color: #0878c0;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  color: #fff;
  background-color: #0878c0 !important;
  border-color: #0878c0;
  outline: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #0878c0;
  border-color: #0878c0;
}
.btn-secondary:active,
.btn-secondary:focus {
  color: #000;
  outline: none;
}
.help-block.error {
  color: #a94442;
  text-align: left;
  font-size: 13px;
  margin-bottom: 0;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
nav.navbar .navbar-nav li.sub-menu ul.dropdown a:hover {
  color: #0878c0;
}
.mb-10 {
  margin-bottom: 10px;
}
.profile-image {
  width: 40px;
  border-radius: 100%;
  height: 40px;
}
.btn-checkbox {
  border-radius: 2px;
}
.btn-checkbox.btn-default:hover,
.btn-checkbox.btn-default:active,
.btn-checkbox.btn-default:focus {
  color: #000;
}
.filter-checkbox {
  margin-right: 10px;
  margin-bottom: 10px;
  
}
.filter-checkbox .btn-checkbox{border-radius: 5px;}
.filter-label {
  font-weight: bold;
  margin: 10px 0;
}
.slideup,
.slidedown {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.slidedown {
  max-height: 900px;
  background-color: #fff;
  border-radius: 20px;
}
.lead-total-count {
  font-weight: bold;
  font-size: 22px;
}
.lead-box {
    margin-bottom: 20px;
    border-radius: 20px;
  }
.lead-count {
  transition: 0.5s;
  padding: 15px;
}
.lead-amount-wrap {
  color: #000;
  transition: 0.5s;
  background: #fff;
  border-radius: 20px;
}
.focus-est.lead-count {
  color: #fff;
  background-color: #0878c0;
  border-radius: 10px;
}
.lead-count a:hover {
  color: #fff;
}

span.lead-price {
  font-size: 24px;
  font-weight: 600;
}
.lead-heading {
  margin: 10px 0;
  font-weight: bold;
  font-size: 16px;
}
.input-number[readonly] {
  background-color: #fff;
}
.job-heading-popup {
  font-size: 18px;
  padding-bottom: 3px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}
.fa-rotate-45 {
  transform: rotate(45deg);
}
.green {
  color: green;
}
.red {
  color: red;
}
.blur-text {
  color: transparent;
  text-shadow: #111 0 0 10px;
  transition: 0.4s;
}
.list-product-title {
  font-size: 15px;
  cursor: pointer;
  font-weight: 600;
  font-family: var(--main-font-family);
}
.product-price {
  float: left;
  margin-right: 9px;
  display: inline;
  font-size: 18px;
  margin-top: 5px;
}
.error-404 {
  margin: 0 auto;
  text-align: center;
}
.error-404 .error-code {
  bottom: 60%;
  color: #4686cc;
  font-size: 96px;
  line-height: 100px;
  font-weight: bold;
}
.error-404 .error-desc {
  font-size: 12px;
  color: #647788;
}
.error-404 .m-b-10 {
  margin-bottom: 10px !important;
}
.error-404 .m-b-20 {
  margin-bottom: 20px !important;
}
.error-404 .m-t-20 {
  margin-top: 20px !important;
}
.otp-timer {
  background-color: #0878c0;
  border-radius: 50%;
  color: #fff;
  padding: 15px;
  font-size: 20px;
}
.badge-notify {
  background: red;
  position: relative;
  top: -20px;
  left: -35px;
}
.main-nav > ul > li .notification {
  color:#fff;
  text-decoration: none;
  position: relative;
  display: inline-block;
   border-radius: 100%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  font-size: 20px;
  margin: 0 10px;
  background: var(--gradient-color);
}
.main-nav > ul > li .notification .badge {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 5px 10px;
  border-radius: 50%;
  background: red;
  color: #0878c0;
}
@media (min-width: 991px) {
  .product-list-wrap .col-sm-6:nth-child(3n + 1) {clear: both; }
}
@media (max-width: 990px) and (min-width: 768px) {
  .product-list-wrap .col-sm-6:nth-child(2n + 1) {clear: both;}
}

/* multi-select dropdown */

.dropdown-content{background-color: #fff; border: 0 !important; box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);}
.dropdown-content > .panel-content{}
.dropdown-content > .panel-content .select-item {background-color: rgb(255, 255, 255) !important; z-index: 20; font-weight: 400;font-size: 12px !important;padding-bottom: 0;}
.dropdown-content > .panel-content .select-item input{margin-left: 10px; vertical-align: middle;float: left;}

.btn-light{height: 45px; line-height: 45px; padding: 0 20px; background-color: #eff2f4;border: 0;}
.btn-light:hover{background-color: #141414;}

.loader{height: 150px; margin: 200px auto;}
/* -------------------------------------------------- */
/* Job Details */
/* -------------------------------------------------- */
.react-responsive-modal-modal{padding: 0 !important; border-radius: 20px;}
.react-responsive-modal-closeButton svg{color: #0b6bd3;}
.job-details{margin: 0;}
.job-details .modal-content {box-shadow: none;border: 0;border-radius: 20px;padding-bottom: 40px;}
.job-details .modal-content .modal-body{padding: 0px 30px 30px 30px;}
.job-details .job-heading-popup {font-size: 24px;padding-bottom: 0px;border-bottom: 0; margin-bottom: 0px;width: 100%; text-align: center; border: 0; font-weight: 600;}
.job-details .modal-content .modal-header{border: 0;padding-top: 30px;}
.job-details .modal-content .modal-body h6{font-size: 14px;text-transform: uppercase;}
.job-details .modal-content .modal-body h5{font-size: 13px; text-transform: uppercase; display: block; letter-spacing: 0.8px;margin-top: 10px; font-weight: 400;margin-bottom: 0;}
.job-details .modal-content .modal-body h4{font-size: 16px;margin-bottom: 0;font-weight: 600;margin-top: 3px; border-bottom: 1px solid #e4e7ea; padding-bottom: 10px;}
.job-details .modal-content .modal-body .btn-signup{background-color: #23ab0c;border-color: #23ab0c;}
.job-details .modal-content .modal-body .btn-signup:hover{background-color: #158c01;}
.btn.focus, .btn:focus {outline: 0;	box-shadow: none;}
.job-details .modal-content .modal-footer{border: 0; padding: 0 15px; }

/* -------------------------------------------------- */
/* Employeer Dashboard */
/* -------------------------------------------------- */
.employeer-dashboard{margin-top: 75px;}
.employeer-dashboard .search-box .form-control {border: 0;height: 45px;}
.employeer-dashboard .job-card{margin-top: 0 !important; margin-bottom: 20px !important;box-shadow: none !important; border: 0; border-radius: 15px;}
.employeer-dashboard .job-card:hover{box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15) !important;}
.employeer-dashboard .job-card .rounded-circle{margin-right: 15px;}
.employeer-dashboard .job-card h4{margin: 0; display: block; font-size: 18px;font-weight: 500;}
.employeer-dashboard .job-card i{font-size: 19px; margin-right: 7px; color: #0878c0;vertical-align: middle;}
.employeer-dashboard .job-card .btn-connect{background-color: #23ab0c; color: #fff; height: 40px; line-height: 40px; padding: 0 10px;}
.employeer-dashboard .job-card .btn-connect:hover{background-color: #deeedc; color: #23ab0c;}
.employeer-dashboard .job-card .btn-connect i{color: #fff;vertical-align: middle;}
.employeer-dashboard .job-card .btn-connect:hover i{color: #23ab0c;}
.employeer-dashboard .job-card .btn-des{border: 1px solid #0878c0; color: #0878c0; letter-spacing: 0.6px; line-height: 40px; height: 40px;padding: 0 10px;font-size: 13px;}
.employeer-dashboard .job-card .btn-des:hover{background-color: #e6eff4; color: #0878c0; border-color: #e6eff4;}
.employeer-dashboard .job-card .btn-des:hover i{color: #0878c0;}
.employeer-dashboard .job-card .salary{justify-content: end;}
.employeer-dashboard .job-card .btn-responses{height: 35px; line-height: 30px; padding: 0 15px; background-color: #014e9c; color: #fff;border-radius: 5px; max-width: 180px; width: 100%;}
.employeer-dashboard .job-card .btn-responses i{color: #fff;margin-right: 0px;font-size: 15px;}
.employeer-dashboard .job-card .btn-responses:hover{background-color: rgba(1, 78, 156, 0.1); color: #014e9c;}
.employeer-dashboard .job-card .btn-responses:hover i{color: #014e9c;}
.employeer-dashboard .job-card .btn-get-leads{height: 35px; line-height: 33px; padding: 0 15px; background-color: rgba(1, 78, 156, 0.1); color: #014e9c;border-radius: 5px; max-width: 180px; width: 100%; margin: 10px 0;}
.employeer-dashboard .job-card .btn-get-leads:hover{background-color: #014e9c; color: #fff;}
.employeer-dashboard .job-card .btn-get-leads:hover i{color: #fff;}
.employeer-dashboard .job-card .btn-get-leads i{margin-right: 0px;font-size: 15px;}
.employeer-dashboard .job-card .btn-edit{height: 35px; line-height: 33px; padding: 0 10px; color: #014e9c;border-radius: 5px; border: 1px solid #014e9c;}
.employeer-dashboard .job-card .btn-edit i{margin-right: 0px;font-size: 15px;}
.employeer-dashboard .job-card .btn-edit:hover{background-color: #014e9c; color: #fff;}
.employeer-dashboard .job-card .btn-edit:hover i{color: #fff;}
.employeer-dashboard .job-card .btn-danger{height: 35px; line-height: 33px; padding: 0 10px; border-radius: 5px; margin-left: 10px;}
.employeer-dashboard .job-card .btn-danger i{color: #fff;margin-right: 0px;}

/* -------------------------------------------------- */
/* Post Jobs */
/* -------------------------------------------------- */
.post_jobs{margin-top: 75px;}
.post_jobs .box-panel{margin-top: 0 !important; margin-bottom: 20px !important;box-shadow: none !important; border: 0; border-radius: 15px;}
.box-panel .btn-save{height: 45px;background: var(--gradient-color);color: #fff; padding: 0 20px; font-weight: 600;border: 0;vertical-align: middle;}
.box-panel .btn-save:hover{background-color: #014a99;}
.post_jobs .box-panel .btn-back339{height: 45px; line-height: 45px; padding: 0 20px; background-color: #eff2f4;}
.post_jobs .box-panel .btn-back:hover{color: #0878c0;}

/* -------------------------------------------------- */
/* Orders */
/* -------------------------------------------------- */
.orders{margin-top: 75px;}
.orders .btn-light{box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);}
.orders .btn-light:hover {color: #b95c21;border-color: #b95c21;background-color: #fff;}
.orders h2 {margin: 30px 0;font-size: 20px;font-family: "montserratmedium";color: rgb(92, 95, 105);text-align: center;}
.orders h2 img{margin: 0 auto; height: 180px;margin-bottom: 20px;}

/* -------------------------------------------------- */
/* Products */
/* -------------------------------------------------- */
.product_list{margin-top: 75px;}
.product_list .search-box .form-control {border: 0;height: 45px;}
.product_list .btn-cart{height: 45px; line-height: 45px; background-color: rgba(25, 110, 225, 0.1); padding: 0 15px; color: #0878c0;}
.product_list .btn-cart i{font-size: 18px;}
.product_list .btn-cart:hover{background-color: #0878c0; color: #fff;}
.product_list .product-list-wrap .box-panel{margin-top: 0; padding: 0;display: inline-block;}
.product_list .product-list-wrap .box-panel .product-img{border-radius: 15px 15px 0px 0px; margin-bottom: 15px;}
.product_list .product-list-wrap .box-panel p{font-size: 13px;}
.product_list .product-list-wrap .box-panel .btn-add-cart{height: 35px; line-height: 35px; background-color: #0878c0; border-radius: 7px; padding: 0 10px; color: #fff; font-size: 13px;letter-spacing: 0.6px;font-family: var(--main-font-family);}
.product_list .product-list-wrap .box-panel .btn-add-cart i{font-size: 18px; vertical-align: middle;margin-right: 3px;}

.product_list .pagination > li.page-item{margin: 0 5px;display: inline-block;}
.product_list .pagination > li.page-item > .page-link{border: 0; padding: 0; height: 35px; width: 35px; border-radius: 100%; line-height: 35px; text-align: center;font-size: 13px;}

.responses{margin-top: 75px;}
.responses h3{margin-top: 0;}
.responses h2{text-align: center;margin-top: 100px; font-size: 24px; font-family: "montserratmedium"; color: rgb(92, 95, 105);}
.responses .box-panel{margin-top: 0px;}
.responses .btn-light:hover {color: #b95c21;border-color: #b95c21;background-color: #fff;}
.responses .response-card i{font-size: 18px;margin-right: 7px; color: #0878c0;}
.responses .response-card h4{font-size: 16px; margin-top: 0; font-weight: 500;}

/* -------------------------------------------------- */
/* Shopping Cart*/
/* -------------------------------------------------- */
.cart{margin-top: 75px;}
.cart .btn-light:hover {color: #b95c21;border-color: #b95c21;background-color: #fff;}
.cart .box-panel{margin-top: 0px;padding: 20px;}
.cart h2{text-align: center;font-size: 20px; margin: 30px 0;}
.cart h2 img{margin: 0 auto; display: block;}
.product-item h4{font-weight: 400;}
.product-item .btn-close{font-size: 22px; color: #ec2727;}

/* -------------------------------------------------- */
/* Job Edit*/
/* -------------------------------------------------- */
.job_edit{margin-top: 75px;}
.job_edit h3{margin-top: 0;}
.job_edit .box-panel{margin-top: 0px; padding: 30px;}
.job_edit .btn-light{height: 45px; line-height: 45px; padding: 0 20px; background-color: #eff2f4;border: 0;}
.job_edit .btn-light:hover{background-color: #141414;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{min-height: 45px;border-radius: 7px;}
.ant-select-multiple .ant-select-selection-item{background-color: #014a99; border-radius: 5px;color: #fff; font-size: 12px;height: 30px;line-height: 30px; border: 0; margin-left: 2px;}
.ant-select-multiple .ant-select-selection-item-remove svg{color: #fff;}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {vertical-align: 2px;}

/* -------------------------------------------------- */
/* Leads List*/
/* -------------------------------------------------- */
.leads_list{margin-top: 75px;}
.leads_list h3{margin-top: 0;}
.leads_list .box-panel{margin-top: 0px; padding: 20px;}
.leads_list h2{text-align: center;margin-top: 100px; font-size: 24px; font-family: "montserratmedium"; color: rgb(92, 95, 105);}
.leads_list .btn-light{box-shadow: 0 7px 20px rgba(0, 0, 0, 0.07);}
.leads_list .btn-light:hover {color: #b95c21;border-color: #b95c21;background-color: #fff;}
.leads_list .btn-connect{background-color: #23ab0c; color: #fff;font-family: "montserratmedium"; height: 40px; line-height: 40px; padding: 0 10px;}
.leads_list .btn-connect:hover{background-color: #deeedc; color: #23ab0c;}
.leads_list .btn-connect i {color: #fff !important;	vertical-align: middle;font-size: 19px; margin-right: 7px;}
.leads_list .box-panel i {color: #0878c0;vertical-align: middle;font-size: 19px;margin-right: 7px;}

/* -------------------------------------------------- */
/* Pagination*/
/* -------------------------------------------------- */
.pagination{text-align: right;}
.pagination .page-item{margin: 0 5px; display: inline-block;}
.pagination .page-item .page-link{border-radius: 100%;border: 0; background-color: #fff;height: 35px; width: 35px; line-height: 35px; padding: 0;text-align: center;box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);}
.pagination .page-item.active .page-link{background: var(--gradient-color); }

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){
  .match_job {margin-top: 70px !important;}
  .box-panel{margin-top: 50px;}
  .recharge { margin-top: 70px; }
  .notification {margin-top: 70px;}
.my_calls {margin-top: 70px;}
.post_jobs{margin-top: 70px;}
.orders{margin-top: 70px;}
  .responses{margin-top: 70px;}
  .job_edit{margin-top: 70px;}
  }
  
@media (max-width: 667px) {
.user-dashboard { margin-top: 60px; }
.user-dashboard .job-card .text-right{text-align: left !important;}
.user-dashboard .job-card .salary{justify-content: start;}
.user-dashboard .btn-filter{width: 100%; margin-top: 20px; font-size: 15px;height: 40px;}
.tagged-section .ant-btn{margin-bottom: 10px;}

.match_job .job-card .text-right{text-align: left !important;}
.match_job .job-card .salary{justify-content: start;}
.match_job .btn-filter{width: 100%; margin-top: 20px; font-size: 15px;height: 40px;}
.match_job .btn-light{margin-top: 20px;}

.my_calls .call-history .company_name{margin-top: 20px;}
.lead-box .lead-amount-wrap .lead-amount .lead-currency{font-size: 14px;}
.lead-box .lead-amount-wrap .lead-amount .lead-price {font-size: 16px;}
.radio-inline{margin-right: 0px;}

.recharge .lead-count .icon{height: 55px; width: 55px;border-radius: 15px;}
.recharge .lead-box .lead-count .content-panel .lead-label-count {font-size: 14px;line-height: 18px;}
.lead-box .lead-amount-wrap .radio-inline{margin-right: 5px;}
.lead-box .lead-amount-wrap .lead-amount .lead-price {font-size: 18px;font-family: "montserratmedium";}

.employeer-dashboard {margin-top: 70px;}
.employeer-dashboard .job-card .text-right {text-align: left !important;}
.employeer-dashboard .job-card .salary {margin-top: 10px;	justify-content: end;}

.employeer-dashboard .job-card .btn-responses{max-width: 100%;}
.employeer-dashboard .job-card .btn-get-leads{max-width: 100%;}
.employeer-dashboard .job-card .btn-edit{max-width: 100%;}

.cart{margin-top: 70px;}
.responses h3{font-size: 18px; line-height: 24px;}
.product_list {margin-top: 60px;}

}

.p-password-input{display: block !important;
	width: 100%;
	height: 45px;
	padding: 6px 12px !important;
	font-size: 14px !important;
	line-height: 1.42857143;
	color: #555 !important;
	background-color: #fff !important;
	background-image: none !important;
	border: 1px solid #ccc !important;
	border-radius: 4px !important;
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
}
.p-password{display: block !important;}
.p-password .pi{font-size: 20px; top: 38% !important;}


/*================================================
Home page CSS
=================================================*/

img { max-width: 100%; height: auto; display: inline-block;}
.ptb-100 { padding-top: 100px; padding-bottom: 100px;}
.pt-100 { padding-top: 100px;}
.pb-100 { padding-bottom: 100px;}
.pb-75 { padding-bottom: 75px;}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area-wrap { padding-top: 50px; padding-bottom: 50px; position: relative; z-index: 1; overflow: hidden; margin-top: 0px;}
.main-banner-content-wrap { position: relative;}
.main-banner-content-wrap h1 { font-size: 75px; font-weight: 900; margin-bottom: 15px;}
.main-banner-content-wrap p { margin-bottom: 0;}
.main-banner-content-wrap .banner-btn { margin-top: 35px;}
.main-banner-content-wrap .layer-shape { position: absolute; top: 27%; right: 0; -webkit-transform: translateY(-40%);transform: translateY(-40%);}
.main-banner-area-with-bg-image .container-fluid { padding-left: 30px; padding-right: 30px;}
.main-banner-area-wrap { position: relative; z-index: 1; overflow: hidden;}
.main-banner-area-wrap .container-fluid { padding-left: 30px; padding-right: 30px;}
.main-banner-area-wrap::before { content: ''; position: absolute; bottom: 0; left: 0; right: 0; background-color: var(--white-color); height: 20px;}
.main-banner-content-wrap { margin-top: -50px;text-align: right}
.main-banner-content-wrap h1 { font-size: 80px; margin-bottom: 15px;line-height: 100px;;}
.main-banner-content-wrap p { margin-bottom: 0;}
.main-banner-bg-shape img { width: 100%; -webkit-transform: scaleX(-1); transform: scaleX(-1);}
.main-banner-bg-shape { position: absolute; bottom: 0; left: 0; right: 0;  z-index: -1;}

/*================================================
Default Btn CSS
=================================================*/
.default-btn { padding: 0px 30px; height: 50px; line-height: 50px; text-align: center; color: var(--white-color) !important; font-size: var(--font-size); font-weight: 600; -webkit-transition: var(--transition); transition: var(--transition); display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center;      -ms-flex-align: center;align-items: center; -webkit-box-pack: center; -ms-flex-pack: center;justify-content: center; position: relative; border-radius: 8px; z-index: 0; background: var(--main-color); overflow: hidden; white-space: nowrap; font-family: var(--heading-font-family);}
.default-btn i {position: absolute; right: 10px;top: 50%; -webkit-transform: translateY(-50%);transform: translateY(-50%); text-align: center; display: inline-block; height: 38px; width: 38px; line-height: 40px; color: var(--white-color); border-radius: 8px; background: var(--gradient-color); -webkit-transition: var(--transition); transition: var(--transition);}
.default-btn:before { content: ''; position: absolute; top: 0; bottom: 0; left: 50%;width: 550px; height: 550px; margin: auto; background: var(--gradient-color); border-radius: 8px; z-index: -1; -webkit-transform-origin: top center;transform-origin: top center; -webkit-transform: translateX(-50%) translateY(-5%) scale(0.4);transform: translateX(-50%) translateY(-5%) scale(0.4);-webkit-transition: -webkit-transform .9s; transition: -webkit-transform .9s; transition: transform .9s;transition: transform .9s, -webkit-transform .9s;}
.default-btn:hover { color: var(--white-color) !important;}
.default-btn:hover:before { -webkit-transition: -webkit-transform 1s; transition: -webkit-transform 1s; transition: transform 1s; transition: transform 1s, -webkit-transform 1s;-webkit-transform: translateX(-45%) translateY(0) scale(1);transform: translateX(-45%) translateY(0) scale(1); -webkit-transform-origin: bottom center;transform-origin: bottom center;}

/*================================================
Top Category Area CSS
=================================================*/
.top-category-area { background-color: #FBFBFB;}
.top-category-card { background-color: #F2FBFF; border: 1px solid #ebebeb; border-radius: 10px; padding: 30px; position: relative; z-index: 1; -webkit-transition: var(--transition); transition: var(--transition); margin-bottom: 25px;}
.top-category-card::before { position: absolute; content: ""; height: 100%; width: 100%; background: var(--gradient-color); left: 0; top: 0; right: 0; z-index: -1; opacity: 0; visibility: hidden; -webkit-transition: var(--transition); transition: var(--transition); border-radius: 10px;}
.top-category-card .category-image { display: inline-block; height: 100px; width: 100px; line-height: 100px; background-color: var(--white-color); text-align: center; border-radius: 5px; -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09); box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09); margin-bottom: 20px;}
.top-category-card .category-image img { width: auto; display: inline-block;}
.top-category-card h3 { font-size: 20px; font-weight: bold; margin-bottom: 0;}
.top-category-card h3 a { color: var(--black-color);}
.top-category-card h3 span { font-size: 18px; font-weight: 600; color: var(--main-color); -webkit-transition: var(--transition); transition: var(--transition);}
.top-category-card:hover::before { opacity: 1; visibility: visible;}
.top-category-card:hover h3 a { color: var(--white-color);}
.top-category-card:hover h3 span { color: var(--white-color);}
.top-category-slides.owl-theme .owl-dots .owl-dot { -webkit-transition: var(--transition); transition: var(--transition);}
.top-category-slides.owl-theme .owl-dots .owl-dot span { width: 10px; height: 10px; margin: 0 5px; background: var(--optional-color); -webkit-transition: var(--transition); transition: var(--transition); border-radius: 50px;}
.top-category-slides.owl-theme .owl-dots .owl-dot:hover span, .top-category-slides.owl-theme .owl-dots .owl-dot.active span { background-color: var(--optional-color); width: 30px;}
.top-category-slides.owl-theme .owl-nav.disabled + .owl-dots { margin-top: 0;}

/*================================================
Section Title CSS
=================================================*/
.section-title-wrap { margin-bottom: 45px;}
.section-title-wrap .title-content h2 { font-size: 34px; margin-bottom: 0;}
.section-title-wrap .title-btn { text-align: end;}
.section-title { text-align: center; max-width: 750px; margin: 0 auto 45px auto; position: relative;}
.section-title h2 { font-size: 42px; margin-bottom: 20px;}
.section-title .section-layer-shape { position: absolute; top: 40%; right: 20%; -webkit-transform: translateY(-40%) translateX(-20%);transform: translateY(-40%) translateX(-20%);}

/*================================================
About Area CSS
=================================================*/
.about-area { overflow: hidden; background-color: #fff;}
.about-content { padding-left: 35px;}
.about-content h3 { font-size: 42px; margin-bottom: 20px;}
.about-content .about-fun-fact { background-color: #FBFBFB; padding: 30px; margin-top: 15px;}
.about-content .about-fun-fact h3 { font-size: 50px; color: var(--main-color); margin-bottom: 5px;}
.about-content .about-fun-fact h3 .sign-icon { font-size: 50px; color: var(--main-color); position: relative; top: 2px;}
.about-content .about-fun-fact p { color: var(--main-color); opacity: 50%; margin-bottom: 0; font-size: var(--font-size); font-weight: 600;}
.about-content .about-btn { margin-top: 30px;}
.about-content .about-btn .default-btn { background: var(--gradient-color);}
.about-content .about-btn .default-btn::before { background: var(--optional-color);}
.about-content .about-btn .default-btn i { background: var(--main-color);}


/*================================================
Job Seeker Area CSS
=================================================*/
.job-seeker-area{background-color: #fff;}
.job-seeker-area .row .col-lg-4 { position: relative;}
.single-job-seeker-card { background-color: var(--white-color); -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); border-radius: 5px; padding: 30px 30px 50px 30px; position: relative; max-width: 285px; margin: 0 auto 25px; -webkit-transition: var(--transition); transition: var(--transition);}
.single-job-seeker-card .seeker-image { margin-bottom: 35px;}
.single-job-seeker-card h3 { font-size: 25px; font-weight: bold; margin-bottom: 0; color: var(--main-color);}
.single-job-seeker-card .step { font-size: 85px; color: var(--main-color); opacity: 5%; font-weight: 900; position: absolute; left: 0; right: 0; bottom: 0; text-align: center;}
.single-job-seeker-card:hover { -webkit-transform: translateY(-5px);transform: translateY(-5px);}
.seeker-arrow-icon { position: absolute; right: -60px; top: 50%; -webkit-transform: translateY(-50%);transform: translateY(-50%); max-width: 120px;}

/*================================================
Play Video Area CSS
=================================================*/
.play-video-area{background-color: #fff;}
.play-video-image img { border: 1px solid #eeeeee;}
.play-video-content { position: relative; margin-left: 45px;}
.play-video-content .play-btn { margin-bottom: 45px;}
.play-video-content .play-btn .video-btn {display: inline-block;  width: 100px; height: 100px; line-height: 110px; background: var(--gradient-color); border-radius: 50%; color: var(--white-color); position: relative; z-index: 1; text-align: center; font-size: 35px;}
.play-video-content .play-btn .video-btn::after { content: ''; display: block; position: absolute; top: 0; right: 0; z-index: -1; bottom: 0; left: 0; border-radius: 50%; border: 1px solid var(--optional-color); -webkit-animation: ripple 5s linear 1s infinite;animation: ripple 5s linear 1s infinite;}
.play-video-content .play-btn .video-btn::before { content: ''; display: block; position: absolute; top: 0; right: 0; z-index: -1; bottom: 0; left: 0; border-radius: 50%; border: 1px solid var(--optional-color); -webkit-animation: ripple 5s linear infinite;animation: ripple 5s linear infinite;}
.play-video-content h3 { font-size: 42px; margin-bottom: 0;}
.play-video-content .layer-shape { position: absolute; bottom: -15px; left: 30% !important; -webkit-transform: translateX(-30%) !important;transform: translateX(-30%) !important;}
@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.play-video-inner-box-image { position: relative;}
.play-video-inner-box-image .video-content { position: absolute; right: 0; top: 50%; -webkit-transform: translateY(-50%);transform: translateY(-50%); padding-top: 50px; padding-bottom: 50px; padding-left: 120px; padding-right: 50px; background-color: #F9F9F9;}
.play-video-inner-box-image .video-content .play-btn { position: absolute; left: -35px; top: 50%; -webkit-transform: translateY(-50%);transform: translateY(-50%);}
.play-video-inner-box-image .video-content .play-btn .video-btn { display: inline-block; width: 100px; height: 100px; line-height: 110px; background: var(--gradient-color);border-radius: 50%; color: var(--white-color); position: relative; z-index: 1; text-align: center; font-size: 35px;}
.play-video-inner-box-image .video-content .play-btn .video-btn::after { content: ''; display: block; position: absolute; top: 0; right: 0; z-index: -1; bottom: 0; left: 0;  border-radius: 50%; border: 1px solid var(--optional-color); -webkit-animation: ripple 5s linear 1s infinite;animation: ripple 5s linear 1s infinite;}
.play-video-inner-box-image .video-content .play-btn .video-btn::before { content: ''; display: block; position: absolute; top: 0; right: 0; z-index: -1; bottom: 0; left: 0; border-radius: 50%; border: 1px solid var(--optional-color); -webkit-animation: ripple 5s linear infinite;animation: ripple 5s linear infinite;}
.play-video-inner-box-image .video-content h3 { font-size: 42px; margin-bottom: 0;}
.play-video-inner-box-image .video-content .layer-shape { position: absolute; bottom: 32px; right: 75px;}


/*================================================
Featured Candidates Area CSS
=================================================*/
.featured-candidates-area { position: relative; z-index: 1; overflow: hidden; background-color: #fff;}
.featured-candidates-area .container-fluid { padding-left: 0; padding-right: 0; overflow: hidden;}
.featured-candidates-image .row .col-lg-4:nth-child(1) .image { margin-bottom: 25px; position: relative; top: 80px;}
.featured-candidates-image .row .col-lg-4:nth-child(2) .image { margin-bottom: 25px;}
.featured-candidates-image .row .col-lg-4:nth-child(3) .image { margin-bottom: 25px; position: relative; top: 95px;}
.featured-candidates-image .image img { border-radius: 100px;}
.featured-candidates-item { padding-left: 50px;}
.featured-candidates-item .featured-candidates-btn a { font-size: var(--font-size); font-weight: bold; color: var(--optional-color);}
.featured-candidates-item .featured-candidates-btn a i { font-size: 20px; position: relative; top: 5px; color: var(--main-color);}
.featured-candidates-content { margin-bottom: 35px; position: relative;}
.featured-candidates-content h3 { font-size: 42px; margin-bottom: 15px; line-height: 1.4;}
.featured-candidates-content p { margin-bottom: 0;}
.featured-candidates-content .layer-shape {position: absolute; top: 36%; right: 9%; -webkit-transform: translateY(-28%) translateX(-2%);transform: translateY(-28%) translateX(-2%);}
.featured-candidates-map-sticky #map { margin-top: 0;}
.featured-candidates-map-sticky #map iframe { width: 100%; height: 100vh; border: 1px solid #E6EDF6; border-radius: 0; margin-bottom: -10px;}
.featured-candidates-with-max-width { max-width: 980px;}
.featured-candidates-with-max-width .featured-candidates-box .featured-candidates-hire { text-align: end;}
.featured-candidates-shape { position: absolute; right: 10px; top: 20px; z-index: -1;}
.featured-candidates-more-item { display: none;}

/*================================================
Review Area CSS
=================================================*/
.review-area { background-image: url(../assets/images/review-bg.jpg); background-position: center center; background-size: cover; background-repeat: no-repeat;}
.review-title { position: relative; margin-bottom: 45px;}
.review-title h2 { font-size: 42px; margin-bottom: 0;}
.review-title .layer-shape { position: absolute; left: 0; bottom: -10px;}
.review-single-card { background-color: var(--white-color); -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);padding: 30px;
  border-radius: 10px; position: relative; z-index: 1;}
.review-single-card::before { position: absolute;content: "";height: 100%;width: 100%; background: var(--gradient-color); left: 0; top: 0; right: 0; z-index: -1; opacity: 0; visibility: hidden; -webkit-transition: var(--transition); transition: var(--transition); border-radius: 10px;}
.review-single-card .review-info { position: relative; padding-left: 125px;}
.review-single-card .review-info .image { position: absolute; left: 0; top: 0;}
.review-single-card .review-info .image img { display: inline-block; width: auto; border-radius: 5px;}
.review-single-card .review-info .content { position: relative; top: 7px;}
.review-single-card .review-info .content h3 { font-size: 20px; font-weight: 600; margin-bottom: 10px; -webkit-transition: var(--transition); transition: var(--transition);}
.review-single-card .review-info .content span { color: var(--paragraph-color); font-size: 14px; font-weight: 400; -webkit-transition: var(--transition); transition: var(--transition);}
.review-single-card .review-info .content .rating { margin-top: 8px;}
.review-single-card .review-info .content .rating i { color: #FFC850; font-size: 15px; -webkit-transition: var(--transition); transition: var(--transition);}
.review-single-card .review-content { margin-top: 35px;}
.review-single-card .review-content i { font-size: 30px; color: var(--optional-color); -webkit-transition: var(--transition);  transition: var(--transition);}
.review-single-card .review-content p { font-size: 20px; font-weight: 400; font-family: var(--heading-font-family); -webkit-transition: var(--transition); transition: var(--transition);}
.review-single-card:hover::before { opacity: 1; visibility: visible;}
.review-single-card:hover .review-info .content h3 { color: var(--white-color);}
.review-single-card:hover .review-info .content span { color: var(--white-color);}
.review-single-card:hover .review-info .content .rating i { color: var(--white-color);}
.review-single-card:hover .review-content i { color: var(--white-color);}
.review-single-card:hover .review-content p { color: var(--white-color);}
.review-slides { max-width: 950px;}
.review-slides.owl-theme .owl-nav { margin-top: 0; -webkit-transition: 1s; transition: 1s; line-height: .01;}
.review-slides.owl-theme .owl-nav [class*=owl-] { color: var(--main-color); margin: 0; position: absolute; left: 20px; top: 50%; -webkit-transform: translateY(-50%);transform: translateY(-50%); padding: 0; background: var(--white-color); border-radius: 50px; -webkit-transition: var(--transition); transition: var(--transition); display: inline-block; width: 45px; height: 45px; line-height: 55px; text-align: center;  font-size: 35px; opacity: 0; visibility: hidden;}
.review-slides.owl-theme .owl-nav [class*=owl-].owl-next { left: auto; right: 20px;}
.review-slides.owl-theme .owl-nav [class*=owl-]:hover { color: var(--white-color); background-color: var(--main-color);}
.review-slides.owl-theme:hover .owl-nav [class*=owl-] { opacity: 1; visibility: visible; left: -30px;}
.review-slides.owl-theme:hover .owl-nav [class*=owl-].owl-next { left: auto; right: -30px;}


/*================================================
Partner Area CSS
=================================================*/
.partner-area { overflow: hidden; background-color: #fff;}
.partner-area.bg-F5F5F5 { background-color: #F5F5F5;}
.partner-image .row .col-lg-4:nth-child(1) .image { margin-bottom: 55px;}
.partner-image .row .col-lg-4:nth-child(2) .image { margin-bottom: 55px;}
.partner-image .row .col-lg-4:nth-child(3) .image { margin-bottom: 55px;}
.partner-content { position: relative;}
.partner-content h3 { font-size: 42px; margin-bottom: 15px; line-height: 1.4;}
.partner-content .layer-shape { position: absolute; top: 28%; left: 27%; -webkit-transform: translateY(-28%) translateX(-60%);transform: translateY(-28%) translateX(-60%);}
.partner-content p { margin-bottom: 0;}
.partner-slides .image-box { text-align: center;}
.partner-slides .image-box img { display: inline-block; max-width: 100%; width: auto; margin: auto;}


/*================================================
Footer Area CSS
=================================================*/
.footer-area { background-color: var(--main-color);}
.single-footer-widget { margin-bottom: 25px;}
.single-footer-widget .widget-logo { margin-bottom: 20px;}
.single-footer-widget .widget-logo a img{height: 40px;}
.single-footer-widget p { color: var(--white-color); margin-bottom: 0;}
.single-footer-widget .widget-social-links { padding: 0; list-style-type: none; margin-top: 15px; margin-bottom: 0;}
.single-footer-widget .widget-social-links li { display: inline-block; margin-right: 15px;}
.single-footer-widget .widget-social-links li span { color: var(--white-color); font-size: var(--font-size); font-weight: 500;}
.single-footer-widget .widget-social-links li a i { color: var(--white-color); font-size: 15px; -webkit-transition: var(--transition); transition: var(--transition); position: relative;
  top: 2px;}
.single-footer-widget .widget-social-links li a i:hover { color: var(--optional-color);}
.single-footer-widget .widget-social-links li:last-child { margin-right: 0;}
.single-footer-widget h3 { margin-bottom: 25px; font-size: 22px; color: var(--white-color); font-weight: 600;}
.single-footer-widget .quick-links { padding: 0; list-style-type: none; margin-bottom: 0;}
.single-footer-widget .quick-links li { margin-bottom: 15px; font-weight: 400; font-size: var(--font-size);}
.single-footer-widget .quick-links li:last-child { margin-bottom: 0;}
.single-footer-widget .quick-links li a { display: inline-block; color: var(--white-color);}
.single-footer-widget .quick-links li a:hover { color: var(--optional-color); margin-left: 10px;}
.single-footer-widget .widget-info { padding: 0; margin-bottom: 0;}
.single-footer-widget .widget-info li { list-style-type: none; color: var(--white-color); font-size: var(--font-size); margin-bottom: 25px; position: relative; padding-left: 32px;}
.single-footer-widget .widget-info li:last-child { margin-bottom: 0;}
.single-footer-widget .widget-info li i { position: absolute; left: 0;top: 0; display: inline-block; height: 25px; width: 25px; line-height: 28px; background-color: var(--optional-color); color: var(--whiteColor); border-radius: 2px; text-align: center; font-size: 12px;}
.single-footer-widget .widget-info li a { color: var(--white-color);}
.single-footer-widget .widget-info li a:hover { color: var(--optional-color);}
.copyright-area { margin-top: 70px; text-align: center;}
.copyright-area .container { border-top: 1px solid #fbfbfb36; padding-top: 30px; padding-bottom: 30px;}
.copyright-area p { color: var(--white-color); font-size: var(--font-size); font-weight: 400;}
.copyright-area p i { position: relative; top: 2px;}
.copyright-area p a { color: var(--white-color); display: inline-block; font-weight: bold;}
.copyright-area p a:hover { color: var(--optional-color);}

@media only screen and (max-width: 767px) {
  .single-footer-widget.ps-5 { padding-left: 0 !important; }
  .single-footer-widget .quick-links li {font-size: 15px; }
  .single-footer-widget .widget-info li { font-size: 15px; padding-left: 30px; }
  .single-footer-widget .widget-info li i { height: 22px; width: 22px; line-height: 25px; font-size: 10px;}
  .copyright-area { margin-top: 25px; }
  .copyright-area p { font-size: 15px; }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-footer-widget.ps-5 {padding-left: 0 !important; }
  .copyright-area {margin-top: 45px; }
}

.footer_find_job_type{padding: 30px 0;}
.footer_find_job_type ul li{line-height: 40px; padding-left: 0;}
.footer_find_job_type ul li a{color: var(--black-color);}
.footer_find_job_city{padding-bottom: 50px;}
.footer_find_job_city ul li{line-height: 40px;padding-left: 0;}
.footer_find_job_city ul li a{color: var(--black-color);}


/*================================================
Go Top CSS
=================================================*/
.go-top { position: fixed; cursor: pointer; bottom: -100px; right: 20px; color: var(--white-color); background-color: var(--optional-color); z-index: 4; width: 45px; text-align: center;
  height: 45px; opacity: 0; visibility: hidden; border-radius: 50%; -webkit-transition: var(--transition); transition: var(--transition); overflow: hidden; -webkit-box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);}
.go-top i { position: absolute; right: 0; left: 0; top: 45%; -webkit-transform: translateY(-45%);transform: translateY(-45%); text-align: center; font-size: 20px; margin-left: auto;margin-right: auto;}
.go-top.active { opacity: 1; visibility: visible; bottom: 50px;}
.go-top:hover { background-color: var(--main-color); color: var(--white-color); -webkit-transition: var(--transition); transition: var(--transition); -webkit-transform: translateY(-5px);transform: translateY(-5px);}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {line-height: 45px;}

.status-error{
  border: 1px solid #a94442;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  border-radius: 7px;
}
.badge-primary{
  background-color: #0878c0;
}
.text-bold{
  font-weight: bold;
}
.custom-input .lead-amount-wrap{
  padding: 14px;
}
.custom-input input{
  height: 36px;
}