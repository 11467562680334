body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  width: 470px;
  padding: 50px;
  text-align: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
  font-size: 16px;
}
.react-confirm-alert-body .btn{height: 45px;padding: 0 20px;font-size: 15px;letter-spacing: 0.6px;width: 100%;}
.react-confirm-alert-body .btn-primary{background: var(--gradient-color);border: 0;color: var(--black-color); margin-right: 0;}
.react-confirm-alert-body .btn-secondary{border: 0; margin-bottom: 15px; background-color: #e6ecf0;}
.react-confirm-alert-body .btn-secondary:hover {background-color: var(--black-color);}
.react-confirm-alert-svg { position: absolute; top: 0; left: 0;}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 15px;
  font-weight: 600;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.react-confirm-alert-button-group > button {
  margin-right: 20px;
  border-radius: 5px;
  font-size: 12px;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
