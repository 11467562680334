.fixed-top > .container > .logo .logo-img {
  background-image: url("../assets/images/logo.jpeg");
  margin: 5px 0 !important;
  max-height: 73px !important;
  position: absolute;
  top: 0;
  height: 73px;
  width: 83px;
  background-size: cover;
  transition: all 0.4s ease-in;
}

.fixed-top.header-scrolled > .container > .logo .logo-img {
  background-image: url("../assets/images/logo-name.png");
  margin: 5px 0 !important;
  max-height: 50px !important;
  position: absolute;
  top: 0 !important;
  width: 310px;
  background-size: cover;
  transform: scale(0.8);
  transition: all 0.4s ease-in;
}
.active-header {
  color: #0878c0 !important;
}

.inactive-header {
  padding: 10px 10px 10px 10px;
  border: 0px;
}

@media (max-width: 991px) {
  .fixed-top > .container > .logo .logo-img {
    top: -11px !important;
    transform: scale(0.7) !important;
  }
  .fixed-top.header-scrolled > .container > .logo .logo-img {
    left: -25px;
  }
}
