.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: #44a34c;
    border-color: #44a34c;
}
.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #4a4a4a;
    background-color: #f1f1f1;
    border-color: #ddd;
}
.pagination > li > a,
.pagination > li > span {
    color: #000;
}
.pagination > li > span {
    cursor: pointer;
}