/*! nouislider - 9.2.0 - 2017-01-11 10:35:35 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -ms-user-select: none;
   -moz-user-select: none;
   user-select: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-target {
   position: relative;
   direction: ltr;
 }
 .noUi-base {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
   /* Fix 401 */
 }
 .noUi-connect {
   position: absolute;
   right: 0;
   top: 0;
   left: 0;
   bottom: 0;
 }
 .noUi-origin {
   position: absolute;
   height: 0;
   width: 0;
 }
 .noUi-handle {
   position: relative;
   z-index: 1;
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
   transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
 }
 .noUi-state-drag * {
   cursor: inherit !important;
 }
 /* Painting and performance;
  * Browsers can paint handles in their own layer.
  */
 .noUi-base,
 .noUi-handle {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
 }
 /* Slider size and handle placement;
  */
 .noUi-horizontal {
   height: 8px;
 }
 .noUi-horizontal .noUi-handle {
   width: 21px;
   height: 21px;
   left: -15px;
   top: -6px;
 }
 /* to enable left side cursor */
 /* .noUi-handle.noUi-handle-lower {
  width: 0;
  height: 0;
  border: none;
} */
 .noUi-vertical {
   width: 18px;
 }
 .noUi-vertical .noUi-handle {
   width: 28px;
   height: 28px;
   left: -6px;
   top: -17px;
 }
 /* Styling;
  */
 .noUi-target {
   background: #eee;
   border-radius: 4px;
   box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
   margin-top: 50px;
   padding: 0 15px 0 17px;
 }
 .noUi-connect {
   background: var(--gradient-color);
   -webkit-transition: background 450ms;
   transition: background 450ms;
 }
 /* Handles and cursors;
  */
 .noUi-draggable {
   cursor: ew-resize;
 }
 .noUi-vertical .noUi-draggable {
   cursor: ns-resize;
 }
 .noUi-handle {
   border-radius: 50%;
   background: #fff;
   cursor: default;
   box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
 }
 .noUi-active {
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
 }
 .noUi-vertical .noUi-handle:after {
   top: 17px;
 }
 /* Disabled state;
  */
 [disabled] .noUi-connect {
   background: #0e0707;
 }
 [disabled].noUi-target,
 [disabled].noUi-handle,
 [disabled] .noUi-handle {
   cursor: not-allowed;
 }
 /* Base;
  *
  */
 .noUi-pips,
 .noUi-pips * {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-pips {
   position: absolute;
   color: #999;
 }
 /* Values;
  *
  */
 .noUi-value {
   position: absolute;
   text-align: center;
 }
 .noUi-value-sub {
   color: #ccc;
   font-size: 10px;
 }
 /* Markings;
  *
  */
 .noUi-marker {
   position: absolute;
   background: #CCC;
 }
 .noUi-marker-sub {
   background: #AAA;
 }
 .noUi-marker-large {
   background: #AAA;
 }
 /* Horizontal layout;
  *
  */
 .noUi-pips-horizontal {
   padding: 10px 0;
   height: 80px;
   top: 100%;
   left: 0;
   width: 100%;
 }
 .noUi-value-horizontal {
   -webkit-transform: translate3d(-50%, 50%, 0);
   transform: translate3d(-50%, 50%, 0);
 }
 .noUi-marker-horizontal.noUi-marker {
   margin-left: -1px;
   width: 2px;
   height: 5px;
 }
 .noUi-marker-horizontal.noUi-marker-sub {
   height: 10px;
 }
 .noUi-marker-horizontal.noUi-marker-large {
   height: 15px;
 }
 /* Vertical layout;
  *
  */
 .noUi-pips-vertical {
   padding: 0 10px;
   height: 100%;
   top: 0;
   left: 100%;
 }
 .noUi-value-vertical {
   -webkit-transform: translate3d(0, 50%, 0);
   transform: translate3d(0, 50%, 0);
   padding-left: 25px;
 }
 .noUi-marker-vertical.noUi-marker {
   width: 5px;
   height: 2px;
   margin-top: -1px;
 }
 .noUi-marker-vertical.noUi-marker-sub {
   width: 10px;
 }
 .noUi-marker-vertical.noUi-marker-large {
   width: 15px;
 }
 .noUi-tooltip {
   display: block;
   position: absolute;
   color: #000;
   padding: 5px;
   text-align: center;
 }
 .noUi-horizontal .noUi-tooltip {
   left: -6px;
   bottom: 120%;
 }
 .noUi-vertical .noUi-tooltip {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   top: 50%;
   right: 120%;
 }
 