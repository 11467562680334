.btn-custom-success {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  outline: none;
}
.btn-custom-default {
  border: 1px solid #000;
  outline: none;
}
.btn-custom-default.active,
.btn-custom-default.focus {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-custom-default:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-custom-default:active {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
