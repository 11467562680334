.radio input, .radio-inline input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.radio, .radio-inline {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    margin-right: 10px;
}
.radio {
    padding-bottom: 10px;
}
.checkround {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-color: #0878c0;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
}
.radio input:checked~.checkround:after, .radio-inline input:checked~.checkround:after {
    display: block;
}
.radio .checkround:after, .radio-inline .checkround:after {
    left: 8px;
    top: 8px;
    transform: translate(-50%,-50%);
    content: '';
    width: 12px;
    height: 12px;
    font-size: 12px;
    background-color: #0878c0;
    border-radius: 50px;
}
.checkround:after {
    content: "";
    position: absolute;
    display: none;
}